import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd';
import { isNil } from 'ramda';

import { COMMON } from '@i18n/namespaces';
import { asyncLoadingWrapper } from '@utils/api.util';
import { formValidateFields } from '@utils/antd-form.util';
import useLoading from '@hooks/useLoading';
import { postOneManager } from '@apis/managers';
import { FormValues } from '../interfaces';

interface UseAddOptions {
  onSuccess?: (message: ReactNode) => void;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useAdd = (options: UseAddOptions = {}) => {
  const { onSuccess, onError, throwable } = options;
  const { t } = useTranslation(COMMON);
  const loading = useLoading();

  const submit = async (form: FormInstance<FormValues>) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const [data, error] = await formValidateFields(form);
        if (!isNil(error) || isNil(data)) return;
        const { name, email, phone } = data;
        const mgrId = await postOneManager({ name, email, phone });
        onSuccess?.(t('common:success-message', { action: t('common:added') }));
        return mgrId;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, submit };
};

export default useAdd;
