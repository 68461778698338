import { FormInstance } from 'antd';

export const formValidateFields = async <FormValues = any>(
  form: FormInstance<FormValues>,
): Promise<[data: FormValues, error: null] | [data: null, error: unknown]> => {
  try {
    const data = await form.validateFields();
    return [data, null];
  } catch (error) {
    return [null, error];
  }
};
