import { Fragment, useState, useEffect, useMemo, ReactNode } from 'react';
import { isNil, equals } from 'ramda';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  List,
  Select,
  Checkbox,
  Drawer,
  Button,
  Card,
  Space,
  Skeleton,
  Empty,
  GetProp,
} from 'antd';
import { SelectOutlined, SettingOutlined } from '@ant-design/icons';

import {
  AccountRoleSelectProps,
  WithValueAndLabel,
  CustomSelectOption,
  Value,
} from './interfaces';

type CheckboxValueType = GetProp<typeof Checkbox, 'value'>;

const { Group: CheckboxGroup } = Checkbox;
const { Item: ListItem } = List;

const AccountRoleSelect = <SelectOption, CheckboxOption>(
  props: AccountRoleSelectProps<SelectOption, CheckboxOption>,
) => {
  const {
    value: extValue,
    onChange,
    loading,
    onDrawerClose,
    selectOptions,
    onSelectChange,
    checkboxOptions = [],
    itemTitleKey = 'value',
    itemContentKey = 'value',
  } = props;
  const [value, setValue] = useState(extValue);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] =
    useState<WithValueAndLabel<CustomSelectOption<SelectOption>>>();
  const [checked, setChecked] = useState<string[]>([]);
  const [indeterminate, setIndeterminate] = useState(
    !!checked.length && checked.length < checkboxOptions.length,
  );
  const [checkAll, setCheckAll] = useState(
    !!checked.length && checked.length === checkboxOptions.length,
  );
  const checkboxOptionMap = useMemo(
    () =>
      checkboxOptions.reduce((result, options) => {
        result[options.value] = options;
        return result;
      }, {} as { [value: string]: WithValueAndLabel<CheckboxOption> }),
    [checkboxOptions],
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setChecked([]);
    setIndeterminate(false);
    setCheckAll(false);
    setSelected(undefined);
    onDrawerClose?.();
  };

  const handleSelectChange = (
    orgId: string,
    option:
      | WithValueAndLabel<CustomSelectOption<SelectOption>>
      | WithValueAndLabel<CustomSelectOption<SelectOption>>[],
  ) => {
    if (Array.isArray(option)) return;
    const data = value?.[option.mgrOrgId];
    if (!isNil(data)) {
      const checked = data.orgAcctRoles.map(({ value }) => value);
      setChecked(checked);
    } else setChecked([]);
    setIndeterminate(false);
    setCheckAll(false);
    setSelected(option);
    onSelectChange?.(option);
  };

  const handleListItemSettingOnClick = (
    option: WithValueAndLabel<CustomSelectOption<SelectOption>>,
  ) => {
    handleDrawerOpen();
    handleSelectChange(option.id, option);
  };

  const handleCheckAllChange = (e: CheckboxChangeEvent) => {
    setChecked(
      e.target.checked ? checkboxOptions.map((option) => option.value) : [],
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleCheckboxChange = (list: CheckboxValueType[]) => {
    setChecked(list as string[]);
    setIndeterminate(!!list.length && list.length < checkboxOptions.length);
    setCheckAll(list.length === checkboxOptions.length);
  };

  const handleSave = () => {
    if (isNil(selected)) return;
    let next: Value<SelectOption, CheckboxOption>;
    if (checked.length === 0) {
      const { [selected.mgrOrgId]: _, ...otherValue } = value || {};
      next = { ...otherValue };
    } else {
      next = {
        ...(value || {}),
        [selected.mgrOrgId]: {
          mgrOrg: selected,
          orgAcctRoles: checked.map((value) => checkboxOptionMap[value]),
        },
      };
    }

    setValue(next);
    onChange?.(next);
    handleDrawerClose();
  };

  useEffect(() => {
    if (equals(extValue, value)) return;
    setValue(extValue);
  }, [extValue]);

  useEffect(() => {
    setIndeterminate(
      !!checked.length && checked.length < checkboxOptions.length,
    );
    setCheckAll(!!checked.length && checked.length === checkboxOptions.length);
  }, [checkboxOptions]);

  return (
    <Fragment>
      <List
        split={false}
        footer={
          <Button icon={<SelectOutlined />} onClick={handleDrawerOpen}>
            Select Roles
          </Button>
        }
        dataSource={Object.values(value || {})}
        renderItem={(item) => (
          <ListItem>
            <Card
              style={{ width: '100%' }}
              type="inner"
              size="small"
              title={item.mgrOrg[itemTitleKey] as ReactNode}
              extra={
                <Button
                  type="link"
                  size="small"
                  onClick={() => handleListItemSettingOnClick(item.mgrOrg)}
                >
                  <SettingOutlined />
                </Button>
              }
            >
              <List
                size="small"
                dataSource={item.orgAcctRoles}
                renderItem={(subItem) => (
                  <ListItem>{subItem[itemContentKey] as ReactNode}</ListItem>
                )}
              />
            </Card>
          </ListItem>
        )}
      />
      <Drawer
        title="Select Account Role"
        open={open}
        onClose={handleDrawerClose}
        footer={
          <Space>
            <Button
              type="primary"
              disabled={isNil(selected)}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button type="text" onClick={handleDrawerClose}>
              Cancel
            </Button>
          </Space>
        }
      >
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Select
            value={selected?.value}
            style={{ width: '100%' }}
            options={selectOptions}
            onChange={handleSelectChange}
          />
          {checkboxOptions.length === 0 ? (
            <Empty />
          ) : (
            <Skeleton active paragraph={{ rows: 6 }} loading={loading}>
              <Space direction="vertical">
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={handleCheckAllChange}
                  checked={checkAll}
                >
                  All
                </Checkbox>
                <CheckboxGroup value={checked} onChange={handleCheckboxChange}>
                  <Space direction="vertical">
                    {checkboxOptions?.map((option) => (
                      <Checkbox
                        key={option.value}
                        value={option.value}
                        disabled={option.disabled}
                      >
                        {option.label}
                      </Checkbox>
                    ))}
                  </Space>
                </CheckboxGroup>
              </Space>
            </Skeleton>
          )}
        </Space>
      </Drawer>
    </Fragment>
  );
};

export default AccountRoleSelect;
export type { Value as AccountRoleSelectValue } from './interfaces';
