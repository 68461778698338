import { ReactNode } from 'react';
import { FormInstance } from 'antd';
import { isNil } from 'ramda';

import { asyncLoadingWrapper } from '@utils/api.util';
import { formValidateFields } from '@utils/antd-form.util';
import useLoading from '@hooks/useLoading';
import { patchOneManager } from '@apis/managers';
import { FormValues } from '../interfaces';

interface UseEditOptions {
  onSuccess?: (message: ReactNode) => void;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useEdit = (props: UseEditOptions = {}) => {
  const { onSuccess, onError, throwable } = props;
  const loading = useLoading();

  const submit = async (
    mgrId: string,
    form: FormInstance<FormValues>,
    initialValues?: FormValues,
  ) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const [data, error] = await formValidateFields(form);
        if (!isNil(error) || isNil(data)) return false;
        const { roles = {}, ...otherData } = data;
        const { roles: originRoles = {} } = initialValues || {};
        const [addManagerOrgRoles, removeManagerOrgRoleIds] = Object.values(
          roles,
        ).reduce(
          (result, role) => {
            const { mgrOrg, orgAcctRoles } = role;
            const originRole = originRoles[mgrOrg.mgrOrgId];
            if (isNil(originRole)) {
              result[0] = [
                ...result[0],
                ...orgAcctRoles.map(({ id }) => ({
                  managerOrganizationId: mgrOrg.mgrOrgId,
                  organizationAccountRoleId: id,
                })),
              ];
            } else {
              // add
              const originOrgAcctRoleIds = originRole.orgAcctRoles.map(
                ({ id }) => id,
              );
              const addOrgAcctRoles = orgAcctRoles.filter(
                ({ id }) => !originOrgAcctRoleIds.includes(id),
              );
              result[0] = [
                ...result[0],
                ...addOrgAcctRoles.map(({ id }) => ({
                  managerOrganizationId: mgrOrg.mgrOrgId,
                  organizationAccountRoleId: id,
                })),
              ];

              // remove
              const orgAcctRoleIds = orgAcctRoles.map(({ id }) => id);
              const removeOrgAcctRoles = originRole.orgAcctRoles.filter(
                ({ id }) => !orgAcctRoleIds.includes(id),
              );
              result[1] = [
                ...result[1],
                ...removeOrgAcctRoles.map(
                  ({ mgrOrgRoleId }) => mgrOrgRoleId as string,
                ),
              ];
            }
            return result;
          },
          [[], []] as [
            addManagerOrgRoles: {
              managerOrganizationId: string;
              organizationAccountRoleId: string;
            }[],
            removeManagerOrgRoleIds: string[],
          ],
        );

        // remove
        Object.keys(originRoles).forEach((mgrOrgId) => {
          if (!isNil(roles[mgrOrgId])) return;
          removeManagerOrgRoleIds.push(
            ...originRoles[mgrOrgId].orgAcctRoles.map(
              ({ mgrOrgRoleId }) => mgrOrgRoleId as string,
            ),
          );
        });

        await patchOneManager(mgrId, {
          ...otherData,
          addManagerOrgRoles,
          removeManagerOrgRoleIds,
        });
        onSuccess?.('Successfully updated.');
        return true;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, submit };
};

export default useEdit;
