import {
  createElement,
  useState,
  useRef,
  useEffect,
  useMemo,
  Suspense,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import {
  theme,
  Menu,
  Row,
  Col,
  Typography,
  Dropdown,
  Space,
  Tooltip,
  Grid,
  Skeleton,
  MenuProps,
} from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { isNil, isNotNil } from 'ramda';
import { useShallow } from 'zustand/react/shallow';

import { LAYOUTS_BASIC_LAYOUT } from '@i18n/namespaces';
import { pickState, useLayoutRibbonStore, useMenuStore } from '@stores';
import Forbidden from '@components/Forbidden';
import LocaleButton from '@components/LocaleButton';
import { Managers } from '@interfaces/stores';
import {
  getAvatarContent,
  getCollapsedWidth,
  isScreenGteBreakpoint,
  createLoadingMenuItems,
} from './utils';
import { BREAKPOINT } from './constants';
import { BasicLayoutProps } from './interfaces';
import TitleSkeleton from './components/TitleSkeleton';
import AvatarSkeleton from './components/AvatarSkeleton';
import OrganizationLabel from './components/OrganizationLabel';
import {
  BasicContainer,
  BasicMain,
  BasicHeader,
  BasicTitle,
  BasicTitleWrapper,
  BasicContent,
  BasicSider,
  createTriggerIcon,
  BasicAvatarWrapper,
  BasicAvatar,
  OrganizationSelect,
  ContentRibbon,
} from './styleds';

const MenuClosed = createTriggerIcon(MenuUnfoldOutlined);
const MenuOpened = createTriggerIcon(MenuFoldOutlined);
const { Text } = Typography;
const { useBreakpoint } = Grid;
const loadingMenuItems = createLoadingMenuItems(4);

const BasicLayout = (props: BasicLayoutProps) => {
  const {
    name,
    title,
    display,
    contentLoading,
    isForbidden,
    defaultOpenKeys,
    defaultSelectedKeys,
    menuItems,
    managerMeOrgs,
    managerMeOrg,
    defaultManagerMeOrg,
    onManagerMeOrgChange,
    siderWidth = 240,
    onLogOut,
    onMenuItemClick,
    goBackText: redirectText,
    onGoBack: redirectUrl,
  } = props;
  const { t } = useTranslation(LAYOUTS_BASIC_LAYOUT);
  const {
    token: { colorBgContainer, colorPrimary },
  } = theme.useToken();
  const location = useLocation();
  const screens = useBreakpoint();
  const layoutRibbonState = useLayoutRibbonStore(
    useShallow((state) => pickState(['text', 'color', 'placement'], state)),
  );
  const layoutRibbonAction = useLayoutRibbonStore(
    useShallow((state) => pickState(['has', 'reset'], state)),
  );
  const menuStore = useMenuStore(
    useShallow((state) =>
      pickState(['label', 'openKeys', 'selectedKeys', 'set'], state),
    ),
  );
  const [collapsed, setCollapsed] = useState(false);
  const previousCollapsed = useRef(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const isDropdownOpen = useRef(false);

  const managerMeOrgsValueMap = useMemo(
    () =>
      managerMeOrgs?.reduce((result, managerMeOrg) => {
        result[managerMeOrg.value] = managerMeOrg;
        return result;
      }, {} as { [value: string]: Managers.MeOrgSelectOption }) || {},
    [managerMeOrgs],
  );

  const updateCollapsed = (next: boolean) => {
    previousCollapsed.current = collapsed;
    setCollapsed(next);
  };

  const handleTooltipOpenChange = (open: boolean) => {
    if (!isDropdownOpen.current) setOpenTooltip(open);
  };

  const handleDropdownOpenChange = (open: boolean) => {
    if (open) setOpenTooltip(false);
    isDropdownOpen.current = open;
  };

  const handleClickBasicMain = () => {
    if (isScreenGteBreakpoint(screens?.[BREAKPOINT])) return;
    if (!collapsed) updateCollapsed(true);
  };

  const handleMenuItemClick: MenuProps['onClick'] = (info) => {
    if (isNotNil(onMenuItemClick)) onMenuItemClick(info);
    else {
      const { key, keyPath } = info;
      const [_, ...openKeys] = keyPath;
      menuStore.set({ openKeys, selectedKeys: [key] });
    }
  };

  const handleSubMenuOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    menuStore.set({ openKeys: openKeys });
  };

  useEffect(() => {
    const { pathname } = location;
    if (!/\/org\/_\w+$/.test(pathname)) return;
    menuStore.set({ openKeys: [], selectedKeys: [] });
  }, [location]);

  useEffect(() => {
    if (isForbidden && layoutRibbonAction.has()) layoutRibbonAction.reset();
  }, [isForbidden, layoutRibbonAction.has()]);

  return (
    <BasicContainer hasSider>
      <BasicSider
        trigger={null}
        breakpoint={BREAKPOINT}
        width={siderWidth}
        collapsible
        collapsed={collapsed}
        collapsedWidth={getCollapsedWidth(
          isScreenGteBreakpoint(screens?.[BREAKPOINT]),
        )}
        onBreakpoint={(isBroke) => {
          if (isBroke) updateCollapsed(true);
          else updateCollapsed(previousCollapsed.current);
        }}
      >
        <Menu
          theme="dark"
          defaultOpenKeys={defaultOpenKeys}
          defaultSelectedKeys={defaultSelectedKeys}
          openKeys={menuStore.openKeys}
          selectedKeys={menuStore.selectedKeys}
          items={display ? menuItems : loadingMenuItems}
          mode="inline"
          onClick={handleMenuItemClick}
          onOpenChange={handleSubMenuOpenChange}
        />
      </BasicSider>
      <BasicMain
        onClick={handleClickBasicMain}
        marginLeft={
          isScreenGteBreakpoint(screens?.[BREAKPOINT])
            ? collapsed
              ? getCollapsedWidth(isScreenGteBreakpoint(screens?.[BREAKPOINT]))
              : siderWidth
            : 0
        }
      >
        <BasicHeader backgroundColor={colorBgContainer}>
          <Row wrap={false}>
            <Col flex={1}>
              {createElement(collapsed ? MenuClosed : MenuOpened, {
                hoverColor: colorPrimary,
                onClick: () => updateCollapsed(!collapsed),
              })}
              <TitleSkeleton loading={!display}>
                <BasicTitleWrapper>
                  <BasicTitle level={4} ellipsis>
                    {title}
                  </BasicTitle>
                </BasicTitleWrapper>
              </TitleSkeleton>
            </Col>
            <Col flex="0 0 auto">
              <Space size="middle">
                <OrganizationSelect
                  display={!isNil(managerMeOrgs)}
                  defaultValue={
                    !isNil(managerMeOrgs) &&
                    managerMeOrgs.length > 0 &&
                    !isNil(defaultManagerMeOrg) &&
                    managerMeOrgsValueMap[defaultManagerMeOrg]
                      ? defaultManagerMeOrg
                      : undefined
                  }
                  value={
                    !isNil(managerMeOrgs) &&
                    managerMeOrgs.length > 0 &&
                    !isNil(managerMeOrg) &&
                    managerMeOrgsValueMap[managerMeOrg]
                      ? managerMeOrg
                      : undefined
                  }
                  onChange={onManagerMeOrgChange}
                  options={managerMeOrgs?.map(
                    ({ value, label, isDefault }) => ({
                      value,
                      label: (
                        <OrganizationLabel
                          label={label}
                          isDefault={isDefault}
                        />
                      ),
                    }),
                  )}
                />
                <LocaleButton />
                <Dropdown
                  trigger={['click']}
                  placement="bottomRight"
                  overlayStyle={{ minWidth: 240 }}
                  onOpenChange={handleDropdownOpenChange}
                  menu={{
                    items: [
                      {
                        key: 'logout',
                        label: (
                          <Space>
                            <LogoutOutlined />
                            <Text>{t('layouts.basic-layout:logout')}</Text>
                          </Space>
                        ),
                        onClick: onLogOut,
                      },
                    ],
                  }}
                >
                  <Tooltip
                    title={name}
                    open={openTooltip}
                    onOpenChange={handleTooltipOpenChange}
                  >
                    <BasicAvatarWrapper onClick={(e) => e.preventDefault()}>
                      <AvatarSkeleton loading={!display}>
                        <BasicAvatar>
                          {getAvatarContent(name || 'UNKNOWN USER')}
                        </BasicAvatar>
                      </AvatarSkeleton>
                    </BasicAvatarWrapper>
                  </Tooltip>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </BasicHeader>
        <ContentRibbon
          display={display && !contentLoading}
          {...layoutRibbonState}
        >
          <BasicContent
            backgroundColor={colorBgContainer}
            hasRibbon={display && !contentLoading && layoutRibbonAction.has()}
          >
            <Skeleton
              active
              paragraph={{ rows: 6 }}
              loading={!display || contentLoading}
            >
              {isForbidden ? (
                <Forbidden goBackText={redirectText} onGoBack={redirectUrl} />
              ) : (
                <Suspense
                  fallback={<Skeleton active paragraph={{ rows: 6 }} />}
                >
                  <Outlet />
                </Suspense>
              )}
            </Skeleton>
          </BasicContent>
        </ContentRibbon>
      </BasicMain>
    </BasicContainer>
  );
};

export default BasicLayout;
export type { BasicLayoutProps };
