import { AxiosInstance } from 'axios';

import { Organizations, Permissions, Utils } from '@interfaces/apis';
import { formatResponse, formatResponseWithMeta } from '@utils/api.util';
import { superAdminAxios, managerAxios } from './axios';

export const getOrganizations = async (
  query?: Utils.OptionalPagination<Organizations.FindManyOrg>,
) =>
  superAdminAxios
    .get<Utils.WeMoReplyWithMeta<Organizations.Organization>>('organizations', {
      params: query,
    })
    .then(formatResponseWithMeta);

export const getOneOrganization = async (orgId: string) =>
  superAdminAxios
    .get<Utils.WeMoReply<Organizations.Organization>>(`organizations/${orgId}`)
    .then(formatResponse);

export const postOneOrganization = async (data: Organizations.CreateOneOrg) =>
  superAdminAxios
    .post<Utils.WeMoReply<Organizations.Organization['id']>>(
      'organizations',
      data,
    )
    .then(formatResponse);

export const patchOneOrganization = async (
  orgId: string,
  data: Organizations.UpdateOneOrg,
) => superAdminAxios.patch(`organizations/${orgId}`, data);

export const getOneMgrOrg = async (orgId: string) =>
  managerAxios
    .get<Utils.WeMoReply<Organizations.MgrOrgInfo>>(
      `organizations/${orgId}/info`,
    )
    .then(formatResponse);

const createGetManyOrgAcctRoleByOrg =
  (axios: AxiosInstance) =>
  async (
    orgId: string,
    query?: Utils.OptionalPagination<Organizations.FindManyOrgAcctRoleByOrg>,
  ) =>
    axios
      .get<Utils.WeMoReplyWithMeta<Organizations.OrgAcctRole>>(
        `organizations/${orgId}/account-roles`,
        { params: query },
      )
      .then(formatResponseWithMeta);

export const getManyOrgAcctRoleByOrgFromMgr =
  createGetManyOrgAcctRoleByOrg(managerAxios);

export const getManyOrgAcctRoleByOrgFromSuperAdmin =
  createGetManyOrgAcctRoleByOrg(superAdminAxios);

export const getManyPermissionByOrg = async (orgId: string) =>
  managerAxios
    .get<Utils.WeMoReplyMany<Permissions.Permission>>(
      `organizations/${orgId}/permissions`,
    )
    .then(formatResponse);

export const postOneCustomizedAcctRole = async (
  orgId: string,
  data: Organizations.CreateOneCustomizedAcctRole,
) =>
  managerAxios
    .post<Utils.WeMoReply<Organizations.OrgAcctRole['id']>>(
      `organizations/${orgId}/customized-roles`,
      data,
    )
    .then(formatResponse);

export const patchOneCustomizedAcctRole = async (
  orgId: string,
  orgAcctRoleId: string,
  data: Organizations.UpdateOneCustomizedAcctRole,
) =>
  managerAxios.patch(
    `organizations/${orgId}/customized-roles/${orgAcctRoleId}`,
    data,
  );

export const getOneOrgAcctRoleByOrg = async (
  orgId: string,
  orgAcctRoleId: string,
) =>
  managerAxios
    .get<Utils.WeMoReply<Organizations.OrgAcctRole>>(
      `organizations/${orgId}/account-roles/${orgAcctRoleId}`,
    )
    .then(formatResponse);
