import styled from 'styled-components';
import { Space, Button } from 'antd';

export const Container = styled(Space)`
  width: 100% !important;
  display: flex;
`;

export const AddButton = styled(Button)`
  flex: 0 0 auto;
  height: auto !important;
`;

export const RemoveItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  opacity: 0.3;
`;
