import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { isNil } from 'ramda';
import { Space, Button, Form, Input, Row, Col, List, Spin } from 'antd';

import {
  SUPER_ADMIN_FETCH_ALL_PERMISSION_ERROR,
  SUPER_ADMIN_ADD_GLOBAL_ACCT_ROLE_SUCCESS,
  SUPER_ADMIN_ADD_GLOBAL_ACCT_ROLE_ERROR,
  SUPER_ADMIN_EDIT_GLOBAL_ACCT_ROLE_SUCCESS,
  SUPER_ADMIN_EDIT_GLOBAL_ACCT_ROLE_ERROR,
  SUPER_ADMIN_FETCH_ONE_GLOBAL_ACCT_ROLE_ERROR,
} from '@constants/notifications';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import EditorHeader from '@components/EditorHeader';
import ContainerSkeleton from '@components/ContainerSkeleton';
import DrawerCheckbox from '@components/DrawerCheckbox';
import useAllPerm from './hooks/useAllPerm';
import useAdd from './hooks/useAdd';
import useEdit from './hooks/useEdit';
import useOneGlobalAcctRole from './hooks/useOneGlobalAcctRole';
import { FormValues, PermissionDataSource } from './interfaces';
import { Container } from './styleds';

const { useForm, Item: FormItem } = Form;
const { Item: ListItem } = List;
const { Meta: ListItemMeta } = ListItem;

const SuperAdminGlobalAccountRoleEditor = () => {
  const navigate = useEnhancedNavigate();
  const { globalAcctRoleId } = useParams<{ globalAcctRoleId?: string }>();
  const { errorNotify, successNotify } = useGlobalNotification();
  const [form] = useForm<FormValues>();
  const allPerm = useAllPerm({
    onError: errorNotify(SUPER_ADMIN_FETCH_ALL_PERMISSION_ERROR),
  });
  const oneGlobalAcctRole = useOneGlobalAcctRole({
    defaultLoading: !isNil(globalAcctRoleId),
    onError: errorNotify(SUPER_ADMIN_FETCH_ONE_GLOBAL_ACCT_ROLE_ERROR),
  });
  const add = useAdd({
    onSuccess: successNotify(SUPER_ADMIN_ADD_GLOBAL_ACCT_ROLE_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_ADD_GLOBAL_ACCT_ROLE_ERROR),
  });
  const edit = useEdit({
    onSuccess: successNotify(SUPER_ADMIN_EDIT_GLOBAL_ACCT_ROLE_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_EDIT_GLOBAL_ACCT_ROLE_ERROR),
  });

  const formInitialValues = useMemo(
    () =>
      isNil(oneGlobalAcctRole.data)
        ? undefined
        : {
            name: oneGlobalAcctRole.data.name,
            permissionIds: oneGlobalAcctRole.data.permissions.map(
              (perm) => perm.id,
            ),
          },
    [oneGlobalAcctRole.data],
  );

  const handleGoBackClick = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    if (isNil(globalAcctRoleId)) {
      const globalAcctRoleId = await add.submit(form);
      if (!isNil(globalAcctRoleId))
        navigate(`${globalAcctRoleId}`, { replace: true });
    } else {
      const result = await edit.submit(
        globalAcctRoleId,
        form,
        formInitialValues,
      );
      if (result) await oneGlobalAcctRole.fetch(globalAcctRoleId);
    }
  };

  const permissionListItemRender = useCallback(
    (option: PermissionDataSource) => <ListItemMeta title={option.name} />,
    [],
  );

  useEffect(() => {
    allPerm.fetch();
    if (!isNil(globalAcctRoleId)) {
      oneGlobalAcctRole.fetch(globalAcctRoleId);
    }
  }, [globalAcctRoleId]);

  return (
    <Spin spinning={allPerm.loading || add.loading || edit.loading}>
      <ContainerSkeleton loading={oneGlobalAcctRole.loading}>
        <Container direction="vertical" size="large">
          <EditorHeader
            title={
              globalAcctRoleId
                ? 'Edit Global Account Role'
                : 'Add Global Account Role'
            }
            onGoBack={handleGoBackClick}
          />
          <Row gutter={[24, 0]}>
            <Col lg={12} xs={24}>
              <Form<FormValues>
                form={form}
                layout="vertical"
                initialValues={formInitialValues}
              >
                <FormItem label="Name" name="name" rules={[{ required: true }]}>
                  <Input />
                </FormItem>
                <FormItem label="Permissions" name="permissionIds">
                  <DrawerCheckbox<PermissionDataSource>
                    title="Permissions"
                    buttonText="Select Permission"
                    itemRender={permissionListItemRender}
                    options={allPerm.data}
                  />
                </FormItem>
              </Form>
            </Col>
            <Col lg={12} xs={24}></Col>
          </Row>
          <Space>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button type="text" onClick={handleGoBackClick}>
              Cancel
            </Button>
          </Space>
        </Container>
      </ContainerSkeleton>
    </Spin>
  );
};

export default SuperAdminGlobalAccountRoleEditor;
