import { useCallback, useRef, useState } from 'react';
import { isNil, omit } from 'ramda';

import { Organizations, Utils } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import { getManyOrgAcctRoleByOrgFromSuperAdmin } from '@apis/organizations';

interface UseAllMgrOrgOptions {
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useAllOrgAcctRole = (options: UseAllMgrOrgOptions = {}) => {
  const { onError, throwable } = options;
  const loading = useLoading();
  const [data, setData] = useState<Organizations.OrgAcctRole[]>();

  const fetch = async (
    orgId: string,
    query: Organizations.FindManyOrgAcctRoleByOrg = {},
  ) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const [data, _] = await getManyOrgAcctRoleByOrgFromSuperAdmin(orgId, {
          ...query,
          all: true,
        });
        setData(data);
        return data;
      },
      (error) => {
        typeof onError === 'function' && onError(error);
      },
      throwable,
    );
  };

  const clear = () => {
    setData(undefined);
  };

  return {
    loading: loading.loading,
    fetch,
    data,
    clear,
  };
};

export default useAllOrgAcctRole;
