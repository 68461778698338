import styled from 'styled-components';
import { Layout, Row } from 'antd';

const { Content } = Layout;

export const FullSizeContent = styled(Content)`
  height: 100vh;
`;

export const FullSizeRow = styled(Row)`
  height: 100%;
`;
