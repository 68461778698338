import { useState } from 'react';
import { omit } from 'ramda';

import { Managers, Utils } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import { getManagers } from '@apis/managers';

interface UseManagersOptions {
  onError?: (error: unknown) => void;
  throwable?: true;
}

const defaultMeta = {
  page: 1,
  size: 10,
  total: 0,
};

const useManagers = (props: UseManagersOptions = {}) => {
  const { onError, throwable } = props;
  const loading = useLoading();
  const [data, setData] = useState<Managers.Manager[]>([]);
  const [pagination, setPagination] = useState<Utils.WeMoMeta>({
    ...defaultMeta,
  });
  const [query, setQuery] = useState<Managers.FindManyManagerQuery>({});

  const fetch = async (
    query: Utils.RequiredPagination<Managers.FindManyManagerQuery> = omit(
      ['total'],
      pagination,
    ),
  ) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const [data, meta] = await getManagers({
          ...query,
          onlyDefaultOrg: true,
        });
        setData(data);
        setPagination(meta);
        setQuery(omit(['page', 'size'], query));
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  const changePage = async (page: number, size: number) => {
    await fetch({ ...query, page, size });
  };

  const reload = async () => {
    await fetch({ ...query, ...omit(['total'], pagination) });
  };

  const reset = async () => {
    await fetch({ ...omit(['total'], defaultMeta) });
  };

  return {
    loading: loading.loading,
    fetch,
    changePage,
    reload,
    reset,
    data,
    query,
    pagination,
  };
};

export default useManagers;
