import { useState } from 'react';

import { Managers } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import { getOneManager } from '@apis/managers';

interface UseOneManagerOptions {
  defaultLoading?: boolean;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useOneManager = (options: UseOneManagerOptions = {}) => {
  const { defaultLoading = false, onError, throwable } = options;
  const loading = useLoading({ defaultLoading });
  const [data, setData] = useState<Managers.Manager>();

  const fetch = async (mgrId: string) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const data = await getOneManager(mgrId);
        setData(data);
        return data;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, fetch, data };
};

export default useOneManager;
