import { Button, Col, Form, Input, Row, Space, Spin } from 'antd';
import { isNil } from 'ramda';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import ContainerSkeleton from '@components/ContainerSkeleton';
import EditorHeader from '@components/EditorHeader';
import {
  SUPER_ADMIN_ADD_MGR_ERROR,
  SUPER_ADMIN_ADD_MGR_SUCCESS,
  SUPER_ADMIN_EDIT_MGR_ERROR,
  SUPER_ADMIN_EDIT_MGR_SUCCESS,
  SUPER_ADMIN_FETCH_ALL_ORG_ACCT_ROLE_ERROR,
  SUPER_ADMIN_FETCH_ONE_MANAGER_ERROR,
} from '@constants/notifications';
import { OrgAcctRoleType } from '@enums/organizations.enum';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import AccountRoleSelect from './components/AccountRoleSelect';
import useAdd from './hooks/useAdd';
import useAllOrgAcctRole from './hooks/useAllOrgAcctRole';
import useEdit from './hooks/useEdit';
import useOneManager from './hooks/useOneMgr';
import { FormValues } from './interfaces';
import { Container } from './styleds';

const { useForm, Item: FormItem } = Form;

const SuperAdminManagerEditor = () => {
  const { mgrId } = useParams<{ mgrId?: string }>();
  const navigate = useEnhancedNavigate();
  const [form] = useForm<FormValues>();
  const { errorNotify, successNotify } = useGlobalNotification();
  const oneManager = useOneManager({
    defaultLoading: !isNil(mgrId),
    onError: errorNotify(SUPER_ADMIN_FETCH_ONE_MANAGER_ERROR),
  });
  const allOrgAcctRole = useAllOrgAcctRole({
    onError: errorNotify(SUPER_ADMIN_FETCH_ALL_ORG_ACCT_ROLE_ERROR),
  });
  const add = useAdd({
    onSuccess: successNotify(SUPER_ADMIN_ADD_MGR_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_ADD_MGR_ERROR),
  });
  const edit = useEdit({
    onSuccess: successNotify(SUPER_ADMIN_EDIT_MGR_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_EDIT_MGR_ERROR),
  });

  const formInitialValues = useMemo(
    () =>
      isNil(oneManager.data)
        ? undefined
        : {
            name: oneManager.data.name,
            email: oneManager.data.email,
            phone: oneManager.data.phone,
            roles: oneManager.data.managerOrganizations?.reduce(
              (result, mgrOrg) => {
                const {
                  organization,
                  managerOrganizationRoles,
                  ...otherMgrOrg
                } = mgrOrg;

                if (managerOrganizationRoles.length === 0) return result;
                return {
                  ...result,
                  [mgrOrg.id]: {
                    mgrOrg: {
                      mgrOrgId: otherMgrOrg.id,
                      ...organization,
                      value: organization.id,
                      label: organization.name,
                    },
                    orgAcctRoles: managerOrganizationRoles.map(
                      ({ id, organizationAccountRole }) => ({
                        mgrOrgRoleId: id,
                        id: organizationAccountRole.id,
                        name: organizationAccountRole.name,
                        value: organizationAccountRole.id,
                        label: organizationAccountRole.name,
                      }),
                    ),
                  },
                };
              },
              {} as FormValues['roles'],
            ),
          },
    [oneManager.data],
  );

  const handleGoBackClick = () => {
    navigate(-1);
  };

  const handleSelectChange = (option: { id: string }) => {
    allOrgAcctRole.fetch(option.id);
  };

  const handleSubmit = async () => {
    if (isNil(mgrId)) {
      const mgrId = await add.submit(form);
      if (!isNil(mgrId)) navigate(`${mgrId}`, { replace: true });
    } else {
      const result = await edit.submit(mgrId, form, formInitialValues);
      if (result) await oneManager.fetch(mgrId);
    }
  };

  const selectOptions = useMemo(
    () =>
      oneManager.data?.managerOrganizations?.map(({ id, organization }) => ({
        mgrOrgId: id,
        ...organization,
        value: organization.id,
        label: organization.name,
      })),
    [oneManager.data],
  );

  const checkboxOptions = useMemo(
    () =>
      allOrgAcctRole.data?.map(({ id, name, type }) => ({
        id,
        name,
        value: id,
        label: name,
        disabled: OrgAcctRoleType.CUSTOMIZED === type,
      })),
    [allOrgAcctRole.data],
  );

  useEffect(() => {
    if (!isNil(mgrId)) oneManager.fetch(mgrId);
  }, [mgrId]);

  return (
    <Spin spinning={add.loading}>
      <ContainerSkeleton loading={oneManager.loading}>
        <Container direction="vertical" size="large">
          <EditorHeader
            title={mgrId ? 'Edit Manager' : 'Add Manager'}
            onGoBack={handleGoBackClick}
          />
          <Row gutter={[24, 0]}>
            <Col lg={12} xs={24}>
              <Form<FormValues>
                form={form}
                layout="vertical"
                initialValues={formInitialValues}
              >
                <FormItem label="Name" name="name" rules={[{ required: true }]}>
                  <Input />
                </FormItem>
                <FormItem
                  label="Email"
                  name="email"
                  rules={[{ required: true }]}
                >
                  <Input />
                </FormItem>
                <FormItem label="Phone" name="phone">
                  <Input />
                </FormItem>
              </Form>
            </Col>
            <Col lg={12} xs={24}>
              {!isNil(mgrId) && (
                <Form<FormValues> form={form} layout="vertical">
                  <FormItem label="Roles" name="roles">
                    <AccountRoleSelect
                      loading={allOrgAcctRole.loading}
                      onDrawerClose={allOrgAcctRole.clear}
                      selectOptions={selectOptions}
                      onSelectChange={handleSelectChange}
                      checkboxOptions={checkboxOptions}
                      itemTitleKey="name"
                      itemContentKey="name"
                    />
                  </FormItem>
                </Form>
              )}
            </Col>
          </Row>
          <Space>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button type="text" onClick={handleGoBackClick}>
              Cancel
            </Button>
          </Space>
        </Container>
      </ContainerSkeleton>
    </Spin>
  );
};

export default SuperAdminManagerEditor;
