import { RouteObject, Navigate } from 'react-router-dom';
import { i18n, TFunction } from 'i18next';

import { ROUTERS_SUPER_ADMIN } from '@i18n/namespaces';
import { NexusRouteObject } from '@interfaces/routes.interface';
import { excludeNoPathAndFlatRoute } from '@utils/router.util';
import SuperAdminWorkspace from '@templates/SuperAdminWorkspace';
import SuperAdminLogin from '@pages/super-admins/Login';
import SuperAdminInvitation from '@pages/super-admins/Invitation';
import SuperAdminForgetPassword from '@pages/super-admins/ForgetPassword';
import SuperAdminResetPassword from '@pages/super-admins/ResetPassword';
import SuperAdminOrganization from '@pages/super-admins/Organization';
import SuperAdminOrganizationEditor from '@pages/super-admins/OrganizationEditor';
import SuperAdminGlobalAccountRole from '@pages/super-admins/GlobalAccountRole';
import SuperAdminGlobalAccountRoleEditor from '@pages/super-admins/GlobalAccountRoleEditor';
import SuperAdminManager from '@pages/super-admins/Manager';
import SuperAdminManagerEditor from '@pages/super-admins/ManagerEditor';

export const createSuperAdminWorkspaceRoutes = (
  t: TFunction<typeof ROUTERS_SUPER_ADMIN>,
  i18n: i18n,
): NexusRouteObject[] => [
  {
    path: 'organizations',
    label: t('routers.super-admin:organizations'),
    element: <SuperAdminOrganization />,
    children: [
      {
        path: 'editor/:orgId?',
        label: t('routers.super-admin:organizations'),
        element: <SuperAdminOrganizationEditor />,
        excludeMenuItem: true,
      },
    ],
  },
  {
    path: 'global-account-roles',
    label: t('routers.super-admin:global-account-roles'),
    element: <SuperAdminGlobalAccountRole />,
    children: [
      {
        path: 'editor/:globalAcctRoleId?',
        label: t('routers.super-admin:global-account-roles'),
        element: <SuperAdminGlobalAccountRoleEditor />,
        excludeMenuItem: true,
      },
    ],
  },
  {
    path: 'managers',
    label: t('routers.super-admin:managers'),
    element: <SuperAdminManager />,
    children: [
      {
        path: 'editor/:mgrId?',
        label: t('routers.super-admin:managers'),
        element: <SuperAdminManagerEditor />,
        excludeMenuItem: true,
      },
    ],
  },
];

export const createSuperAdminRouter = (
  t: TFunction<typeof ROUTERS_SUPER_ADMIN>,
  i18n: i18n,
): RouteObject[] => [
  {
    path: '/super-admins/log-in',
    element: <SuperAdminLogin />,
  },
  { path: '/super-admins/invitation/:key', element: <SuperAdminInvitation /> },
  {
    path: '/super-admins/forget-password',
    element: <SuperAdminForgetPassword />,
  },
  {
    path: '/super-admins/reset-password/:key',
    element: <SuperAdminResetPassword />,
  },
  {
    path: '/super-admins',
    element: <SuperAdminWorkspace />,
    children: [
      ...excludeNoPathAndFlatRoute(createSuperAdminWorkspaceRoutes(t, i18n)),
      // { path: '', element: <Navigate to="/super-admins/log-in" replace /> },
      {
        path: '*',
        element: <Navigate to="/super-admins/log-in" replace />,
      },
    ],
  },
  {
    path: '/super-admin/*',
    element: <Navigate to="/super-admins/log-in" replace />,
  },
];
