import { useEffect, useMemo } from 'react';
import { Row, Col, Space, Button, Table, Spin } from 'antd';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons';

import { SUPER_ADMIN_FETCH_MANY_ORG_ERROR } from '@constants/notifications';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import ReloadButton from '@components/ReloadButton';
import useOrgs from './hooks/useOrgs';
import { createTableColumns } from './utils';
import { Container } from './styleds';

const SuperAdminOrganization = () => {
  const navigate = useEnhancedNavigate();
  const { errorNotify } = useGlobalNotification();
  const orgs = useOrgs({
    onError: errorNotify(SUPER_ADMIN_FETCH_MANY_ORG_ERROR),
  });

  const handleOnAddClick = () => {
    navigate('editor');
  };

  const handleOnEditClick = (orgId: string) => {
    navigate(`editor/${orgId}`);
  };

  const tableColumns = useMemo(
    () => createTableColumns({ onEditClick: handleOnEditClick }),
    [],
  );

  useEffect(() => {
    orgs.fetch();
  }, []);

  return (
    <Spin spinning={orgs.loading}>
      <Container direction="vertical" size="large">
        <Row justify="space-between">
          <Col>
            <Space>
              <Button icon={<FilterOutlined />} />
              <ReloadButton onClick={orgs.reload} />
            </Space>
          </Col>
          <Col>
            <Space>
              <Button icon={<PlusOutlined />} onClick={handleOnAddClick}>
                Add Organization
              </Button>
            </Space>
          </Col>
        </Row>
        <Table
          rowKey="id"
          scroll={{ x: 'max-content' }}
          columns={tableColumns}
          dataSource={orgs.data}
          pagination={{
            current: orgs.pagination.page,
            pageSize: orgs.pagination.size,
            total: orgs.pagination.total,
            onChange: orgs.changePage,
          }}
        />
      </Container>
    </Spin>
  );
};

export default SuperAdminOrganization;
