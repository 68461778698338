import { MouseEventHandler } from 'react';
import { Typography } from 'antd';
import { useHref, useLinkClickHandler } from 'react-router-dom';
import { isNotNil } from 'ramda';

import { LinkProps } from './interfaces';
const { Link: AntdLink } = Typography;

const Link = (props: LinkProps = {}) => {
  const { children, navigate, target, onClick, ...otherProps } = props;
  const { pathname, search } = window.location;
  const { to, ...linkProps } = navigate || {};
  const href = useHref(to || '#');

  const handleRouterLinkOnClick = useLinkClickHandler<HTMLElement>(to || '#', {
    ...linkProps,
    target: target || navigate?.target,
    state: { prev: `${pathname}${search}`, ...navigate?.state },
  });

  const handleOnClick: MouseEventHandler<HTMLElement> = (event) => {
    onClick?.(event);
    if (!event.defaultPrevented) {
      handleRouterLinkOnClick(event);
    }
  };

  return isNotNil(navigate) ? (
    <AntdLink
      {...otherProps}
      href={href}
      target={target}
      onClick={handleOnClick}
    >
      {children}
    </AntdLink>
  ) : (
    <AntdLink {...otherProps} target={target} onClick={onClick}>
      {children}
    </AntdLink>
  );
};

export default Link;
