import {
  useLocation,
  useNavigate,
  To,
  RelativeRoutingType,
} from 'react-router-dom';
import { isNotNil } from 'ramda';

import { isNotEmpty } from '@utils/ramda.util';
import { useAuthStore } from '@stores';
import useParamsOrgId from '@hooks/useParamsOrgId';
import { el } from '@fullcalendar/core/internal-common';

interface NavigateOptions<
  State extends Record<string, any> = Record<string, any>,
> {
  replace?: boolean;
  state?: State;
  preventScrollReset?: boolean;
  relative?: RelativeRoutingType;
}

export interface NavigateFunction<
  State extends Record<string, any> = Record<string, any>,
> {
  (to: To, options?: NavigateOptions<State>): void;
  (delta: number): void;
}

interface LocationState {
  prev?: string;
}

const useEnhancedNavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const orgId = useParamsOrgId();
  const fallback = useAuthStore((state) => state.fallback);

  const enhancedNavigate: NavigateFunction = (...args) => {
    if (typeof args[0] !== 'number') {
      const [to, options = {} as NavigateOptions] = args as [
        To,
        NavigateOptions | undefined,
      ];
      const { state, replace, ...otherOptions } = options;
      const { pathname, search } = location;
      const nextState = replace
        ? state
        : { prev: `${pathname}${search}`, ...state };
      navigate(to, {
        ...otherOptions,
        replace,
        state: nextState,
      });
    } else {
      const [delta] = args as [number];
      const { prev } = (location.state || {}) as LocationState;
      if (delta === -1) {
        if (isNotNil(prev) && isNotEmpty(prev)) navigate(prev);
        else if (isNotNil(document.referrer) && isNotEmpty(document.referrer))
          if (window.history.length === 1)
            window.location.href = document.referrer;
          else navigate(-1);
        else if (isNotNil(fallback)) navigate(`/org/_${orgId}/${fallback}`);
        else navigate(-1);
      } else {
        navigate(delta);
      }
    }
  };

  return enhancedNavigate;
};

export default useEnhancedNavigate;
