import { useEffect, useMemo } from 'react';
import { Row, Col, Space, Button, Table, Spin } from 'antd';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons';

import { SUPER_ADMIN_FETCH_MANAGERS_ERROR } from '@constants/notifications';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import ReloadButton from '@components/ReloadButton';
import useManagers from './hooks/useManagers';
import { createMainTableColumns } from './utils';
import { Container } from './styleds';

const SuperAdminManager = () => {
  const navigate = useEnhancedNavigate();
  const { errorNotify } = useGlobalNotification();
  const managers = useManagers({
    onError: errorNotify(SUPER_ADMIN_FETCH_MANAGERS_ERROR),
  });

  const handleAddClick = () => {
    navigate('editor');
  };

  const handleEditClick = (mgrId: string) => {
    navigate(`editor/${mgrId}`);
  };

  const mainTableColumns = useMemo(
    () =>
      createMainTableColumns({
        onEditClick: handleEditClick,
      }),
    [],
  );

  useEffect(() => {
    managers.fetch();
  }, []);

  return (
    <Spin spinning={managers.loading}>
      <Container direction="vertical" size="large">
        <Row justify="space-between">
          <Col>
            <Space>
              <Button icon={<FilterOutlined />} />
              <ReloadButton onClick={managers.reload} />
            </Space>
          </Col>
          <Col>
            <Space>
              <Button icon={<PlusOutlined />} onClick={handleAddClick}>
                Add Manager
              </Button>
            </Space>
          </Col>
        </Row>
        <Table
          rowKey="id"
          scroll={{ x: 'max-content' }}
          columns={mainTableColumns}
          dataSource={managers.data}
          pagination={{
            current: managers.pagination.page,
            pageSize: managers.pagination.size,
            total: managers.pagination.total,
            onChange: managers.changePage,
          }}
        />
      </Container>
    </Spin>
  );
};

export default SuperAdminManager;
