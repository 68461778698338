import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { equals, isNil } from 'ramda';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Drawer, Checkbox, Button, Space, List, Empty, GetProp } from 'antd';
import { SelectOutlined } from '@ant-design/icons';

import { COMMON } from '@i18n/namespaces';
import FluidSpace from '@components/FluidSpace';
import {
  DefaultDataSource,
  Option,
  OptionsMap,
  DrawerCheckboxProps,
} from './interfaces';

type CheckboxValueType = GetProp<typeof Checkbox, 'value'>;

const { Group: CheckboxGroup } = Checkbox;
const { Item: ListItem } = List;

const DrawerCheckbox = <DataSource extends DefaultDataSource = any>(
  props: DrawerCheckboxProps<DataSource>,
) => {
  const {
    value: extValue,
    onChange,
    title,
    buttonText = 'Select Options',
    buttonIcon = <SelectOutlined />,
    options = [],
    itemRender,
  } = props;
  const { t } = useTranslation(COMMON);
  const optionsMap = useMemo(
    () =>
      options.reduce((result, option) => {
        result[option.value] = option;
        return result;
      }, {} as OptionsMap<Option<DataSource>>),
    [options],
  );
  const [value, setValue] = useState(extValue);
  const [checked, setChecked] = useState<string[]>(value || []);
  const [open, setOpen] = useState(false);
  const [indeterminate, setIndeterminate] = useState(
    isNil(value) ? false : value.length < options.length,
  );
  const [checkAll, setCheckAll] = useState(
    isNil(value) ? false : value.length === options.length,
  );

  const handleAddClick = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCheckAllChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked ? options.map((option) => option.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleCheckboxChange = (list: CheckboxValueType[]) => {
    setChecked(list as string[]);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
  };

  const handleSave = () => {
    setOpen(false);
    setValue(checked);
    onChange?.(checked);
  };

  const listItemRender = (item: DataSource, index: number) => {
    return <ListItem key={item.id}>{itemRender?.(item, index)}</ListItem>;
  };

  useEffect(() => {
    if (equals(extValue, value)) return;
    setValue(extValue);
    setChecked(extValue || []);
  }, [extValue]);

  return (
    <Fragment>
      <List
        footer={
          <Button icon={buttonIcon} onClick={handleAddClick}>
            {buttonText}
          </Button>
        }
        dataSource={value?.map((item) => optionsMap[item])}
        renderItem={listItemRender}
      />
      <Drawer
        closable={false}
        open={open}
        title={title}
        onClose={handleDrawerClose}
        footer={
          <Space>
            <Button type="primary" onClick={handleSave}>
              {t('common:save')}
            </Button>
            <Button type="text" onClick={handleDrawerClose}>
              {t('common:cancel')}
            </Button>
          </Space>
        }
      >
        <FluidSpace direction="vertical">
          {options.length === 0 ? (
            <Empty />
          ) : (
            <Fragment>
              <Checkbox
                indeterminate={indeterminate}
                onChange={handleCheckAllChange}
                checked={checkAll}
              >
                {t('common:all')}
              </Checkbox>
              <CheckboxGroup value={checked} onChange={handleCheckboxChange}>
                <Space direction="vertical">
                  {options.map((option) => (
                    <Checkbox key={option.id} value={option.value}>
                      {option.label}
                    </Checkbox>
                  ))}
                </Space>
              </CheckboxGroup>
            </Fragment>
          )}
        </FluidSpace>
      </Drawer>
    </Fragment>
  );
};

export default DrawerCheckbox;
export type { WithValueAndLabel } from './interfaces';
