import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd';
import { isNil } from 'ramda';

import { COMMON } from '@i18n/namespaces';
import { asyncLoadingWrapper } from '@utils/api.util';
import { formValidateFields } from '@utils/antd-form.util';
import { postOneOrganization } from '@apis/organizations';
import { FormValues } from '../interfaces';

interface UseAddOptions {
  onSuccess?: (message: ReactNode) => void;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useAdd = (options: UseAddOptions = {}) => {
  const { onSuccess, onError, throwable } = options;
  const { t } = useTranslation(COMMON);
  const [loading, setLoading] = useState(false);

  const submit = async (form: FormInstance<FormValues>) => {
    return await asyncLoadingWrapper(
      setLoading,
      async () => {
        const [data, error] = await formValidateFields(form);
        if (!isNil(error) || isNil(data)) return;
        const { accountRoles, managers, ...otherData } = data;
        const { add } = managers;
        const orgId = await postOneOrganization({
          ...otherData,
          managers: add?.map((manager) => ({ id: manager.id })),
          globalAcctRoleIds: accountRoles,
        });
        onSuccess?.(t('common:success-message', { action: t('common:added') }));
        return orgId;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading, submit };
};

export default useAdd;
